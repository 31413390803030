<template>
    <div v-if="modal">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <div :class=" modal.isPhisical ? 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3' : 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4'">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: modal.activeTab == 0 }"
                       @click="modal.activeTab = 0" id="tab-one-tab"
                       data-toggle="tab" href="#tab-one" role="tab" aria-controls="tab-one"
                       aria-disabled="true">{{ modal.isPhisical ? t('GENERAL.PERSONAL_DATA') : 'Dados da Empresa' }}</a>
                </li>
            </div>
            <div :class=" modal.isPhisical ? 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3' : 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4'">
                <li class="nav-item" role="presentation">
                    <a class="nav-link"
                       :class="{ active: modal.activeTab == 1, 'required': modal.isRequiredClassValidation('address_id')}"
                       @click.stop.prevent="modal.validateSecondAbaClick"
                       id="localization-tab" data-toggle="tab" href="#localization" role="tab"
                       aria-controls="localization"
                       aria-disabled="true">{{ t('CLIENTS.ADDRESS') }} </a>
                </li>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3" v-if="modal.isPhisical">
                <li class="nav-item" role="presentation">
                    <a class="nav-link"
                       :class="{ active: modal.activeTab == 2, 'required': modal.isRequiredClassValidation('comercial_address_id')}"
                       @click.stop.prevent="modal.validateThirdAbaClick"
                       id="addresscompany-tab"
                       data-toggle="tab"
                       href="#addressCompany"
                       role="tab"
                       aria-controls="addressCompany"
                       aria-disabled="true">{{ t('CLIENTS.ADDRESS_COMPANY') }} </a>
                </li>
            </div>
            <div :class="modal.isPhisical ? 'col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3' : 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4'">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: modal.activeTab == 3 }"
                       @click.stop.prevent="modal.validateAnexAbaClick"
                       id="document-tab" data-toggle="tab" href="#document" role="tab" aria-controls="document"
                       aria-disabled="true">{{ t('CLIENTS.DOCUMENT') }}</a>
                </li>
            </div>
        </ul>
        <form @submit.prevent="modal.create">
            <div class="tab-content modal-row2 modal-height-max" id="myTabContent" v-if="modal.client"
                 style="overflow-x: hidden">
                <div class="tab-pane fade" :class="{ active: modal.activeTab == 0, show: modal.activeTab == 0 }"
                     id="tab-one"
                     role="tabpanel" aria-labelledby="allotment-tab">
                    <div class="modal-body">
                        <div class="row gutters">
                            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6" v-if="modal.isPhisical">
                                <div class="form-group">
                                    <label data-cy="client_add_taxpayer" for="taxpayer"
                                           :class="{'required': modal.isRequiredClassValidation('taxpayer')}"
                                    >CPF: </label>
                                    <input type="text" id="taxpayer" name="taxpayer" v-model="modal.client.taxpayer"
                                           class="form-control"
                                           onpaste="return false"
                                           @change="modal.findClient(modal.client.taxpayer)"
                                           placeholder="000.000.000-00"
                                           ondrop="return false"
                                           v-mask="['###.###.###-##']"
                                           @keyup="modal.validateTaxpayer(modal.client.taxpayer)">
                                    <div v-if="modal.invalid.taxpayer" class="validation">
                                        {{ t(modal.invalid.taxpayer) }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" v-if="!modal.isPhisical">
                                <div class="form-group">
                                    <label data-cy="client_add_taxpayer" for="taxpayer"
                                           :class="{'required': modal.isRequiredClassValidation('taxpayer')}"
                                    >CNPJ:</label>
                                    <input type="text" id="taxpayer" name="taxpayer" v-model="modal.client.taxpayer"
                                           class="form-control"
                                           onpaste="return false"
                                           @change="modal.findClient(modal.client.taxpayer)"
                                           placeholder="00.000.000/0000-00"
                                           ondrop="return false"
                                           v-mask="['##.###.###/####-##']"
                                           @keyup="modal.validateTaxpayer(modal.client.taxpayer)">
                                    <div v-if="modal.invalid.taxpayer" class="validation">
                                        {{ t(modal.invalid.taxpayer) }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="modal.isPhisical" class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
                                <div class="form-group">
                                    <label :class="{'required': modal.isRequiredClassValidation('birthday')}"
                                           v-if="modal.isPhisical"
                                           for="birthday"
                                    >{{ t('CLIENTS.BIRTHDAY') }}: </label>
                                    <input data-cy="client_add_birthday"
                                           type="text"
                                           id="birthday"
                                           name="birthday"
                                           v-model="modal.client.birthday"
                                           placeholder="dd-mm-aaaa"
                                           v-mask="['##-##-####']"
                                           class="form-control"
                                           @change="modal.validateDate('birthday')"
                                    >
                                    <div v-if="modal.invalid.birthday" class="validation">{{
                                            modal.invalid.birthday
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div v-else class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
                                <div class="form-group">
                                    <label for="birthday"
                                           :class="{'required': modal.isRequiredClassValidation('birthday')}"
                                    >{{ t('CLIENTS.FOUNDATION_DAY') }}: </label>
                                    <input data-cy="client_add_foundation_day" type="text" id="birthday"
                                           name="foundationDay"
                                           placeholder="dd-mm-aaaa"
                                           v-model="modal.client.birthday"
                                           class="form-control"
                                           v-mask="['##-##-####']"
                                           @change="modal.validateDate('birthday')"
                                    >
                                    <div v-if="modal.invalid.birthday" class="validation">{{
                                            modal.invalid.birthday
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="!modal.isPhisical" class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12">
                                <div class="form-group">
                                    <label :class="{'required': modal.isRequiredClassValidation('creci')}">{{
                                            t('CLIENTS.CRECI')
                                        }}: </label>
                                    <input type="text" id="creci" name="creci"
                                           v-model="modal.client.creci" placeholder="Creci"
                                           class="form-control">
                                    <div v-if="modal.invalid.creci" class="validation">{{ modal.invalid.creci }}</div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" v-if="modal.isPhisical">
                                <div class="form-group">
                                    <label for="name" class="required">{{ t('GENERAL.NAME') }}: </label>
                                    <input data-cy="client_add_name" type="text" id="name" name="name"
                                           v-model="modal.client.name"
                                           maxlength="60"
                                           class="form-control"
                                           placeholder="Nome Completo">
                                    <div v-if="modal.invalid.name" class="validation">{{ modal.invalid.name }}</div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" v-if="!modal.isPhisical">
                                <div class="form-group">
                                    <label for="name"
                                           class="required"
                                    >{{ t('COMPANIES.NAME') }}: </label>
                                    <input type="text" id="name" name="name"
                                           maxlength="60"
                                           v-model="modal.client.corporate_name"
                                           class="form-control">
                                    <div v-if="modal.invalid.name" class="validation">{{ modal.invalid.name }}</div>
                                </div>
                            </div>
                            <div v-if="modal.isPhisical" class="col-xl-4 col-lg-4 col-md-6 col-sm-4 col-12"
                                 id="marital_status_block">
                                <div class="form-group">
                                    <label
                                            :class="{'required': modal.isRequiredClassValidation('marital_status_id')}"
                                    >{{ t('CLIENTS.MARITAL_STATUS') }}: </label>
                                    <Select2 v-if="modal.client?.marital_statuses?.name"
                                             id="marital_status_id" name="marital_status_id"
                                             v-model="modal.client.marital_status_id"
                                             :placeholder="modal.client?.marital_statuses?.name"
                                             :settings="modal.maritalstatus"/>
                                    <Select2 v-else
                                             id="marital_status_id" name="marital_status_id"
                                             v-model="modal.client.marital_status_id"
                                             placeholder="Selecione"
                                             :settings="modal.maritalstatus"/>
                                    <div v-if="modal.invalid.marital_status_id" class="validation">{{
                                            modal.invalid.marital_status_id
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="modal.isPhisical" class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label :class="{'required': modal.isRequiredClassValidation('sex')}">{{
                                            t('GENERAL.SEX')
                                        }}: </label>
                                    <Select2 :settings="{ width: '100%', dropdownParent: '#customModalTwo'}" id="sex"
                                             name="sex" v-model="modal.client.sex" data-cy="client-sex"
                                             placeholder="Selecione"
                                             :options="modal.options"
                                             @select="modal.inputSex(modal.client.sex), modal.isCompany = false"/>
                                    <div v-if="modal.invalid.sex" class="validation">{{ modal.invalid.sex }}</div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" v-if="!modal.isPhisical">
                                <div class="form-group">
                                    <label :class="{'required': modal.isRequiredClassValidation('trade_name')}"
                                           for="trade_name">{{ t('CLIENTS.TRADE_NAME') }}: </label>
                                    <input type="text" id="trade_name" name="trade_name"
                                           v-model="modal.client.trade_name"
                                           class="form-control">
                                    <div v-if="modal.invalid.trade_name" class="validation">{{
                                            modal.invalid.trade_name
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="modal.isPhisical" class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group ">
                                    <label :class="{'required': modal.isRequiredClassValidation('rg')}"
                                           for="rg">RG: </label>
                                    <input type="text" id="rg" name="rg" v-model="modal.client.rg" class="form-control"
                                           maxlength="15">
                                    <div v-if="modal.invalid.rg" class="validation">{{ modal.invalid.rg }}</div>
                                </div>
                            </div>
                            <div v-if="modal.isPhisical" class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label :class="{'required': modal.isRequiredClassValidation('dispatch_date')}"
                                           for="dispatch_date">{{ t('CLIENTS.DISPATCH_DATE') }}: </label>
                                    <input type="text" id="dispatch_date" name="dispatch_date"
                                           placeholder="dd-mm-aaaa"
                                           v-mask="['##-##-####']"
                                           v-model="modal.client.dispatch_date" class="form-control"
                                           @change="modal.validateDate('dispatch_date')"
                                    >
                                    <div v-if="modal.invalid.dispatch_date" class="validation">{{
                                            modal.invalid.dispatch_date
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="modal.isPhisical" class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label :class="{'required': modal.isRequiredClassValidation('dispatching_agency')}"
                                           for="dispatching_agency">{{ t('CLIENTS.DISPATCH_AGENCY') }}: </label>
                                    <input type="text" id="dispatching_agency" name="dispatching_agency"
                                           v-model="modal.client.dispatching_agency" class="form-control"
                                    >
                                    <div v-if="modal.invalid.dispatching_agency" class="validation">
                                        {{ modal.invalid.dispatching_agency }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="modal.isPhisical" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                                 id="profession_block">
                                <label :class="{'required': modal.isRequiredClassValidation('profession_id')}"
                                >{{ t('CLIENTS.PROFESSION') }}: </label>
                                <Select2 :settings="modal.setting"
                                         @select="modal.inputProfession(modal.client.profession_id)"
                                         v-if="modal.client?.profession_id && modal.client?.professions?.termo"
                                         :placeholder="modal.client.professions.termo"
                                         data-cy="client_add_profession"
                                         id="profession_id"
                                         name="profession"
                                         v-model="modal.client.profession_id"
                                ></Select2>
                                <Select2 v-else
                                         :settings="modal.setting"
                                         @select="modal.inputProfession(modal.client.profession_id)"
                                         data-cy="client_add_profession"
                                         id="profession_id"
                                         placeholder="Selecione"
                                         name="profession"
                                         v-model="modal.client.profession_id"
                                ></Select2>
                                <div v-if="modal.invalid.profession_id" class="validation">
                                    {{ modal.invalid.profession_id }}
                                </div>
                            </div>
                            <div v-if="modal.isPhisical" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                <div class="form-group ">
                                    <label :class="{'required': modal.isRequiredClassValidation('income')}"
                                           for="income">Renda: </label>
                                    <input type="text"
                                           id="income"
                                           name="income"
                                           v-money3="modal.valueConfig"
                                           v-model.lazy="modal.client.income"
                                           class="form-control"
                                           maxlength="15">
                                    <div v-if="modal.invalid.income" class="validation">{{ modal.invalid.income }}</div>
                                </div>
                            </div>
                            <div v-if="modal.isPhisical" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                                <div class="form-group ">
                                    <label :class="{'required': modal.isRequiredClassValidation('income_family')}"
                                           for="income_family">Renda Familiar: </label>
                                    <input type="text" id="income_family"
                                           name="income_family"
                                           v-money3="modal.valueConfig"
                                           v-model.lazy="modal.client.income_family"
                                           class="form-control"
                                           maxlength="15">
                                    <div v-if="modal.invalid.income_family" class="validation">{{
                                            modal.invalid.income_family
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="modal.isPhisical" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                <div class="form-group ">
                                    <label :class="{'required': modal.isRequiredClassValidation('work_on_company')}"
                                           for="work_on_company">Empresa em que trabalha: </label>
                                    <input type="text"
                                           id="work_on_company"
                                           name="work_on_company"
                                           v-model="modal.client.work_on_company"
                                           class="form-control"
                                           maxlength="128">
                                    <div v-if="modal.invalid.work_on_company" class="validation">{{
                                            modal.invalid.work_on_company
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label :class="{'required': modal.isRequiredClassValidation('email')}"
                                           for="email">{{ t('GENERAL.EMAIL') }}: </label>
                                    <input data-cy="client_add_email" type="text" id="email" name="email"
                                           v-model="modal.client.email"
                                           class="form-control"
                                           placeholder="exemplo@exem.com">
                                    <div v-if="modal.invalid.email" class="validation">{{ modal.invalid.email }}</div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" id="block_responsable"
                                 v-if="!modal.isPhisical">
                                <div class="form-group">
                                    <label>{{ t('GENERAL.RESPONSABLE') }}: </label>
                                    <select2 :settings="modal.ajaxResponsable"
                                             v-if="modal.client?.responsableName"
                                             :placeholder="modal.client.responsableName"
                                             @select="modal.selectedResponsableId"
                                    ></select2>
                                    <select2 v-else
                                             :settings="modal.ajaxResponsable"
                                             placeholder="Selecione"
                                             @select="modal.selectedResponsableId">
                                    </select2>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6" v-if="modal.isPhisical">
                                <div class="form-group">
                                    <label :class="{'required': modal.isRequiredClassValidation('nationality')}"
                                           for="nationality">{{ t('CLIENTS.NATIONALITY') }}: </label>
                                    <input type="text" id="nationality" name="nationalitynationality"
                                           v-model="modal.client.nationality"
                                           class="form-control">
                                    <div v-if="modal.invalid.nationality" class="validation">
                                        {{ modal.invalid.nationality }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6" v-if="modal.isPhisical">
                                <div class="form-group">
                                    <label :class="{'required': modal.isRequiredClassValidation('naturalness')}"
                                           for="naturalness">{{ t('CLIENTS.BIRTH_PLACE') }}: </label>
                                    <input type="text" id="naturalness" name="naturalness"
                                           v-model="modal.client.naturalness"
                                           class="form-control">
                                    <div v-if="modal.invalid.naturalness" class="validation">
                                        {{ modal.invalid.naturalness }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" v-if="modal.isPhisical">
                                <div class="form-group">
                                    <label :class="{'required': modal.isRequiredClassValidation('mother_name')}"
                                           for="mother_name">{{ t('CLIENTS.MOTHER_NAME') }}: </label>
                                    <input type="text" id="mother_name" name="mother_name"
                                           v-model="modal.client.mother_name"
                                           class="form-control">
                                    <div v-if="modal.invalid.mother_name" class="validation">
                                        {{ modal.invalid.mother_name }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" v-if="modal.isPhisical">
                                <div class="form-group">
                                    <label for="father_name"
                                           :class="{'required': modal.isRequiredClassValidation('father_name')}"
                                    >{{ t('CLIENTS.FATHER_NAME') }}: </label>
                                    <input type="text" id="father_name" name="father_name"
                                           v-model="modal.client.father_name"
                                           class="form-control">
                                    <div v-if="modal.invalid.father_name" class="validation">
                                        {{ modal.invalid.father_name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12">
                                <phoneComponentInline :required="modal.isRequiredClassValidation('phones')"
                                                      :phonesIndex="modal.client.phones"
                                                      :isInvalid="modal.invalid.phones"
                                                      :clear-data-phone="modal.clearDataPhone"
                                                      @alterDescription="modal.alterDescription"
                                                      @alterType="modal.alterType"
                                                      @alterNumber="modal.alterNumber"
                                                      @cleaned="modal.cleanedPhone"
                                                      @update="modal.updatePhone($event)"
                                                      @create="modal.createPhone($event)"
                                ></phoneComponentInline>
                                <div v-if="modal.invalid.phones" class="validation">{{ modal.invalid.phones }}</div>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                                <div class="form-group">
                                    <label for="signature"
                                           :class="{'required': modal.isRequiredClassValidation('description')}"
                                    >Observação: </label>
                                    <editor :init="modal.configTinyMce" v-model="modal.client.description"
                                            id="description"
                                            @update:modelValue="modal.summernoteChange($event)"
                                            tinymce-script-src="https://tiny.r2app.com.br/js/tinymce/tinymce.min.js"
                                    >
                                    </editor>
                                    <div v-if="modal.invalid.description" class="validation">
                                        {{ modal.invalid.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" :class="{ active: modal.activeTab == 1, show: modal.activeTab == 1 }"
                     id="localization"
                     role="tabpanel" aria-labelledby="localization-tab">
                    <address-generic v-if="modal.address"
                                     :is-invalid="modal.invalid.address"
                                     :is-cep-required="modal.isRequiredClassValidation('address_id')"
                                     :addressIndex="modal.address"
                                     @update="modal.updateAddress($event)"
                    />
                </div>
                <div class="tab-pane fade" :class="{ active: modal.activeTab === 2, show: modal.activeTab === 2 }"
                     id="comercialAddress" v-if="modal.isPhisical"
                     role="tabpanel" aria-labelledby="comercialaddress-tab">
                    <address-generic v-if="modal.activeTab == 2"
                                     :is-invalid="modal.invalid.addressComercial"
                                     :addressIndex="modal.comercialAddress"
                                     :is-cep-required="modal.isRequiredClassValidation('comercial_address_id')"
                                     @update="modal.updateComerciaAddress($event)"
                    />
                </div>
                <div class="tab-pane fade" :class="{ active: modal.activeTab == 3, show: modal.activeTab == 3 }"
                     id="document"
                     role="tabpanel" aria-labelledby="document-tab">
                    <div class="row w-100 ml-2">
                        <div class="card w-100 ">
                            <div class="card-body row gutters pb-2">
                                <div class="col-6">
                                    <label class="required">{{ t('CLIENTS.TYPE_ANEX') }}: </label>
                                    <Select2 :settings="{width: '100%'}" :options="modal.optionsDriveType"
                                             placeholder="Selecione um Tipo de Anexo"
                                             id="typeAnex" name="typeAnex"
                                             v-model="modal.anex.drive_files_type_id"
                                    ></Select2>
                                </div>
                                <div class="col-6 d-flex">
                                    <button class="btn btn-primary  ml-3 align-self-end"
                                            @click.prevent.stop="modal.setFile" id="new_anex"
                                            :disabled="!modal.anex.drive_files_type_id">
                                        <i class="icon-file_upload"></i> Selecionar Anexo
                                    </button>
                                </div>
                                <p class="ml-2 mb-0 red">Extensões Suportadas: .pdf; .png; .jpg; .jpeg</p>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row"
                             v-if="modal.client?.documents?.length">
                            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 font-bold pl-4">
                                <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                        this.t('CLIENTS.DOCUMENTS.NAME')
                                    }}
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 font-bold">
                                <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                        this.t('CLIENTS.TYPE_ANEX')
                                    }}
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 font-bold">
                                <div class="font-weight-bolder mt-2 ml-4 text-left">{{
                                        this.t('CLIENTS.DOCUMENTS.SIZE')
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row p-2 w-100"
                             v-for="(document, key) in modal.client.documents" :key="key"
                             :class="key != 0 ? 'border-top2': ''">
                            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                <div class="list-primary  text-left">
                                    {{ document.name }}
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <Select2 :settings="{width: '100%'}" :options="modal.optionsDriveType"
                                         placeholder="Selecione um Tipo de Anexo"
                                         disabled
                                         :id="`typeAnex${key}`" :name="`typeAnex${key}`"
                                         v-model="document.drive_files_type_id"
                                ></Select2>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <div class="list-primary  text-left">
                                    {{ modal.formatSize(document.file_size) }}
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 d-flex">
                                <div class="list-primary  text-left pointer">
                                    <i class="icon-download pl-3" v-if="document.id"
                                       @click="modal.downloadDocument(document)"></i>
                                </div>
                                <div class="list-primary pointer">
                                    <i class="icon-trash-2 pl-2" @click="modal.documentDelete(document, key)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card w-100" v-if="!modal.client?.documents?.length">
                            <div class="card-header"></div>
                            <div class="card-body pt-0">
                                <div class="text-center">
                                    <h5>Nenhum Anexo Foi Cadastrado</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="justify-content: space-between">
                <div>
                    <h5 class="ml-3" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
                </div>
                <div>
                    <button class="btn btn-secondary mr-2" type="button" v-if="modal.activeTab != 0"
                            @click="modal.backActiveTab">{{ t('ALLOTMENT.PREVIOUS') }}
                    </button>
                    <button data-cy="client_add_next" class="btn btn-secondary mr-2" type="button"
                            v-if="modal.activeTab !== 3"
                            @click="next()">{{ t('ALLOTMENT.NEXT') }}
                    </button>
                    <button data-cy="client_add_save" class="btn btn-primary" type="submit">
                        {{ t('ACTIONS.SAVE') }}
                    </button>
                </div>
            </div>
        </form>
        <upload-modal :uploadFile="modal.uploadFile" @upload="modal.uploadFile = $event"
                      @import="modal.uploadDocument($event)"></upload-modal>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import Select2 from 'vue3-select2-component';
import Editor from '@tinymce/tinymce-vue';
import uploadModal from '../../../components/layouts/uploadModal';
import phoneComponentInline from '../../../components/layouts/phoneComponentInline';
import addressGeneric from '@/components/layouts/addressComponent.vue';
import {Money3Directive} from 'v-money3';

export default {
    name: "contentCreatePersonModalDesktop",
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    directives: {money3: Money3Directive},
    props: ['modalIndex'],
    components: {
        Select2,
        addressGeneric,
        Editor,
        uploadModal,
        phoneComponentInline,
    },
    watch:{
        modalIndex(){
            this.modal = this.modalIndex;
        }
    },
    mounted() {
        this.modal = this.modalIndex;
    },
    data() {
        return {
            modal: null,
        }
    }
}
</script>

<style scoped>

</style>